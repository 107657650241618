
@import "colorscheme.scss";
$mainbgcolor: $clwhite;
$secondarybgcolor: $cldarkblue;
/*=======================================*/
$maintxtcolor: $clwhite;
$primary-btn-background:$mainbgcolor;
$primary-background:$mainbgcolor;
$navbackgroundcl: $cldarkblue;  
$cfhandlenametext:$clgreen;
$selectbox-background-color:$clorange;
$primary-element-background:$clred ;
$primary-txt-selected-color: $primary-element-background;
$primary-txt-color-light: $clwhite;
$border-light:$primary-txt-color-light;
$primary-element-selected-background:$primary-txt-color-light ;
$nav-active:$clblue;
$input-background-light:$cllightgray;
$btnhover:$clorange;
$btnhovertext:$clwhite;
$nav-hover:$cldarkgray; 
$nav-text-color:$cllightgray;
$main-font: Roboto;
$padding-fix: 5px;
$font-size: 10px; 
$dotsize:10px;
$text-weight:bold;