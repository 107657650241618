@import "variables.scss";
#mainnav {
    font-family: $main-font;
    font-weight: $text-weight;
    padding-left: $padding-fix !important;
    background-color: $navbackgroundcl;
    margin-bottom: 0;
    li {
        display: inline;
        list-style-type: none;
        margin: 0;
        color: $primary-txt-color-light;
    }
    a {
        color: $primary-txt-color-light!important;
        transition: letter-spacing 0.5s;
        text-decoration: none;
        padding: 10px 20px 10px 20px;
        display: inline-block;
    }
    a:hover {
        letter-spacing: 0.2em;
        background-color: $nav-hover;
    }
    .active {
        background-color: $nav-active;
        color: $nav-text-color !important;
    }
}
