@import "variables.scss";
#mainContainerCF {
    background-color: $primary-background-light;
    button{
        margin:5px;
        background-color:$clgreen;
        color:$primary-txt-color-light;
        border: none;
    }
    canvas{
        //max-width: 1000px!important;
        max-height: 300px!important;
    }
}
form {
    padding: 20px;
    label {
        color: $primary-txt-color-dark !important;
        input {
            margin: 0px 20px 0px 20px !important;
        }
    }
}
#CFHeader {
    background-color: $primary-background;
    color: $primary-txt-color-light;
    min-width: 100%;
    padding: 20px;
    b {
        color: $cfhandlenametext;
    }
}
#personalInf {
    padding: 20px;
    span {
        display: block;
    }
    .clred,
    .clgreen {
        font-weight: bold;
    }
    .clred {
        color: $clred;
    }
    .clgreen {
        color: $clgreen;
    }
    .gray {
        color: gray;
    }
    .green {
        color: green;
    }
    .cyan {
        color: cyan;
    }
    .blue {
        color: blue;
    }
    .violet {
        color: violet;
    }
    .orange {
        color: orange;
    }
    .red {
        color: red;
    }
    .bred {
        color: rgb(94, 0, 0);
    }
    .default{
        color:burlywood;
    }
    
}
