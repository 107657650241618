@import "variables.scss";
#landpagemain,
#homelink a {
    font-family: "ROBOTO", "helvetica neue", helvetica, arial, sans-serif;
}
#landpagemain {
    background-color: $primary-background; 
    background-image: url("");
    // opacity: 90%;
    background-position: center;
    border: 100px white;
    background-repeat: no-repeat;
    // background-blend-mode:overlay;
    background-position: 0px -300px;
    background-size: cover;
    min-width: 100%;
    min-height: 90vh;
    padding-top: 50px;
}
#landingtext{
    padding-top: 50px;
}
#landingimg {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 25px;
}
#landingimg img {
    border: solid 25px #ffffff;
    border-radius: 50%;
}
.container {
    padding: 0;
}
.welcomeText {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    font-size: 50px;
    text-shadow: yellow;
    color: $clblue;
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    text-shadow: 2px 2px #00000093;
    text-transform: uppercase;
    font-family: "ROBOTO", "helvetica neue", helvetica, arial, sans-serif;
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
}
.welcome_Text {
    width: 100%;
    min-height: 100vh;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    font-size: 100px;
    text-shadow: yellow;
    color: white;
    background-color: yellowgreen;
    background-image: url("https://res.cloudinary.com/mrcat/image/upload/v1620297525/Web/img/img_icsm7d.jpg");
    background-repeat: repeat;
    background-position: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 150px;
    padding-top: 20vh;
    text-align: center;
    font-weight: bold;
    text-shadow: 12px 2px #00000000;
    text-transform: uppercase;

    font-weight: 900;
    -webkit-font-smoothing: antialiased;
}
#homelink { 
    width: 50%;
    margin: 0 auto;
    background-color: Red;
    text-align: center;
    padding: 5px;
}
#homelink a {
    text-decoration: None !important;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #ffffff !important;
}
