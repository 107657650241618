
$clgreen: #1ba94c;
$clblue: #3498db;
$clred : red;
$clorange:#e67e22;
$cltitledarkgray:#3a3b3b;
$cldarkgray:#b4b5b6;
$cllightgray:#d8d8d8;
$clwhite:#ffffff;
$primary-background-light:#eeeeee;
$primary-txt-color-dark: #222222;
$cldarkblue:#19242e;